import React         from "react";
import {Pages}                from "./Pages";
import ReactDOM      from "react-dom";
import {UserSection} from "../components/UserSection";

export class UsersEdit extends Pages {

    constructor(params) {
        super(params);
    }

    initPageHandlers(params) {
        super.initPageHandlers(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <UserSection
                showPersonSection={true}
                edit={true}
                user={this.data.element}/>,
            document.getElementById('userForm')
        );

    }

}
